.modal {
  min-width: 500px;
  &__title {
    font-size: 25px;
    font-weight: 700;
    margin: 15px 0 0;
  }

  &__description {
    display: flex;
    align-items: center;
    font-size: 18px;
    margin: 15px 0 0;

    & > span {
      margin-left: 5px;
      display: flex;
      align-items: center;
    }
  }

  &__addInfo {
    margin: 5px 0;
    color: #1e1e1e;
    font-size: 18px;
  }

  &__copy {
    cursor: pointer;
    transition: all .3s;
    &:hover {
      color: #1da1f2;
    }
  }
  &__icon {
    color: #1da1f2;
  }
}