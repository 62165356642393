.report {

  &__wrapper {
    padding: 15px;
  }

  &__title {
    margin: 0;
  }

  &__table {
    margin-top: 20px
  }

  &__content {
    display: grid;
    grid-template-columns: 5fr 5fr;
    gap: 20px;
    margin-top: 15px;
  }
}

@media (max-width: 991px) {
  .report {
    &__content {
      display: grid;
      grid-template-columns: 12fr;
    }
  }
}