.compare {
  padding: 15px;
}

.filters {
  display: flex;
  align-items: flex-end;
  gap: 20px;
  margin-top: 12px;
  flex-wrap: wrap;
  margin-bottom: 15px;

  &__item {
    max-width: 250px;
  }

  &__btn {
    max-height: 30px;
    width: auto;
    padding: 0;
  }
}

.different {
  background-color: #ffd7d7 !important;
}