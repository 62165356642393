.warning {
  &__wrapper {
    position: relative;
  }
}
.error {
  color: #ef4848;
  left: 0;
  display: block;
  text-align: left;
  &__nowrap {
    white-space: nowrap;
  }
}
