.manage {
  padding: 20px;

  &__content {
    display: grid;
    grid-template-columns: 3fr 8fr;
    gap: 20px;
  }

  &__right {
    width: 100%;

    &__content {
      margin-top: 15px;
      max-height: calc(100vh - 325px);
      overflow-y: auto;
      display: block;
      height: 100vh;
    }

    &_title {
      margin: 0;
      color: #042d50;
    }
  }

  &__select {
    &_content {
      margin-top: 15px;
    }

    &_list {
      padding: 0;
      list-style: none;
      overflow-y: auto;
      background-color: #fafafa;
      border: 1px solid #ccc;
      border-radius: 3px;
      max-height: calc(100vh - 280px);
      display: block;
      height: 100vh;

      &Item {
        padding: 7px 10px;
        cursor: pointer;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:hover {
          background: #efefef;
          color: #4995e3;
        }

        &Active {
          color: #4995e3;
          text-decoration: underline;
        }

        &Disabled {
          color: #b7b7b7;
          background-color: #f5f5f5 !important;
          cursor: default;

          &:hover {
            background-color: #f5f5f5 !important;
            color: #b7b7b7;
          }
        }
        &Label {
          display: flex;
          align-items: center;
          gap: 5px;
        }
        &Icon {
          font-size: 16px !important;
        }

        &Actions {
          display: flex;
          align-items: center;
          gap: 1px;
        }

        &:nth-child(2n) {
          background-color: #e7e7e7;
        }
      }
    }
  }

  &__menu {
    &_btn {
      padding: 4px 12px !important;
      width: auto !important;
      height: auto !important;

      &Reset {
        background-color: #136943 !important;
        min-width: auto !important;
        height: 100% !important;
        padding: 9px !important;
      }

      &Wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &Margin {
          margin-top: 15px;
        }
      }
    }
  }

  &__search {
    &_wrapper {
      margin-top: 15px;
    }
  }
}