.loaderData {
  &__ldsRing {
    display: inline-block;
    position: relative;
    width: 50px;
    height: 50px;

    &_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    &_item {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 50px;
      height: 50px;
      margin: 8px;
      border: 4px solid;
      border-color: #ccc transparent transparent transparent;
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

      &:nth-child(1) {
        animation-delay: -0.45s;
      }

      &:nth-child(2) {
        animation-delay: -0.3s;
      }

      &:nth-child(3) {
        animation-delay: -0.15s;
      }

      &Small {
        width: 20px;
        height: 20px;
        margin: 0;
        border: 2px solid;
        border-color: #ccc transparent transparent transparent;
      }
      &White {
        border-color: #fff transparent transparent transparent;
      }
    }

    &Small {
      width: 20px;
      height: 20px;
    }
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}