body {
  .app-sidebar-userbox {
    display: flex;
    justify-content: center;
    border-radius: 0;
    text-align: center;
    margin: 0;

    &__wrapper {
      background: #f0f2f6;
      padding: 20px;
      margin: 0;
      border-radius: 0;
    }
    .app-sidebar-userbox-avatar {
      width: 100px;
      height: 100px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
    }

    .app-sidebar-userbox-name {
      font-weight: 600;
      font-size: 20px;
      text-align: center;
      color: #000000;
      margin-bottom: 4px;
      padding-left:10px;
    }

    .app-sidebar-userbox-position {
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: #575c76;
      margin-bottom: 17px;
    }

    .app-sidebar-userbox-description {
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      /* identical to box height, or 150% */
      color: #000000;
    }

    .app-sidebar-userbox-btn {
      position: absolute;
      right: 20px;
      top: 20px;
    }

    .app-sidebar-userbox-btn-profile {
      opacity: 1;
      visibility: visible;
    }
  }

  .app-sidebar-userbox--collapsed {
    padding-left: 20px;
    padding-right: 20px;
    background: transparent;

    .app-sidebar-userbox-avatar {
      width: 34px;
      height: 34px;
      margin-bottom: 0;
    }

    .app-sidebar-userbox-name {
      opacity: 0;
      height: 0;
    }

    .app-sidebar-userbox-btn {
      opacity: 0;
    }

    .app-sidebar-userbox-btn-profile {
      opacity: 0;
      visibility: hidden;
    }
  }
}

.align-items-center {
  align-items: center !important;
}
