.wrapper {
  scrollbar-width: thin;
  scrollbar-color: #1375D1 transparent;
  &::-webkit-scrollbar {
    width: 4px;
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #1375D1;
    border-radius: 4px;
  }
  &:focus {
    outline: none
  }
  &::placeholder {
    color: #000;
  }
  &_type_ {
    &thin {
      &::-webkit-scrollbar {
        width: 2px;
      }
    }
  }
}