
.menu {
  min-width: 400px;
  &__form {
    &_item {
      margin-top: 15px;

      &Flex {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
    &_icons {
      display: flex;
      gap: 12px;
      align-items: center;
    }
    &_btn {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
  }

  .date {
    &__wrapper {
      min-width: 300px;
    }
  }
}