.add {
  &__title {
    margin: 0 !important;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 7px;
    margin-bottom: 15px;
  }

  &__contragent {
    font-weight: 600;
    &_sub {
      padding-left: 15px;
      font-weight: initial;
    }
  }

  &__form {
    min-width: 500px;

    &_item {
      width: 100%;
      margin-top: 10px;

      &:first-of-type {
        margin-top: 0;
      }

      &Checkbox {
        margin-top: 0 !important;
      }
    }

    @media (max-width: 576px) {
      min-width: unset;
      width: 100%;
    }
  }

  &__btn {
    margin-top: 12px !important;

    &_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__section {
    &_title {
      font-weight: 700;
      font-size: 16px;
      padding: 5px;
      background-color: #e2e7ff;
      text-align: center;
      border-radius: 2px;
      color: #2f2f2f;
      margin-top: 20px;
    }
  }
}
