.settings {
  &__wrapper {
    padding: 15px;
    display: flex;
    gap: 20px;

    @media (max-width: 768px) {
      flex-direction: column-reverse;
    }
  }

  &__header {
    display: flex;
    gap: 13px;

    &_search {
      max-width: 250px;
    }

    &_filters {
      background-color: #fff;
      padding: 10px 15px 30px 15px;
      border-radius: 4px;
      box-shadow: 0 0 4px #dedede;
      margin-top: 20px;

      &Hide {
        display: none;
      }

      &Content {
        display: flex;
        align-items: flex-end;
        gap: 10px;
        row-gap: 5px;
        margin-top: 12px;
        flex-wrap: wrap;
      }

      &Active {
        min-width: 200px;
      }

      &Small {
        min-width: 140px;
      }

      &Btn {
        background-color: #5465a2 !important;
      }

      &Title {
        margin: 0;
      }
    }
  }

  &__clickable {
    color: #1da1f2;
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }

    &_icon {
      font-size: 19px !important;
    }
  }

  &__password {
    font-size: 20px !important;
    color: #e42734;
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  &__contragent {
    font-weight: 600;

    &_sub {
      padding-left: 15px;
      font-weight: initial;
    }
  }

  &__users {
    width: 70%;

    &_content {
      margin-top: 15px;
    }

    &_active {
      background-color: #e0ed9c !important;

      &:hover {
        background-color: #d9ea7d !important;
      }
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  &__info {
    width: 28%;

    &__content {
      margin-top: 15px;
    }

    &_item {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 12px;
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  &__search {
    margin-bottom: 10px;
  }

  &__login {
    text-decoration: unset;

    &:hover {
      text-decoration: underline;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;
    justify-content: center;
  }
}
